.home-flags-container {
	padding: 0 !important;
}

.home-flags-container > ul {
	width: 200px !important;
}

.home-flags {
	border: none !important;
	padding: 0 !important;
}

.home-flags > span {
	padding-left: 2px !important;
	padding-right: 2px !important;
}

.home-flags > span > span {
	padding-left: 2px !important;
	padding-right: 2px !important;
}

#rfs-btn {
	background-color: #ffffff;
}

#rfs-btn:after {
	content: none;
}
